import React, { useState, useRef } from 'react';
import { Container, Form, Button, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { PersonBadge, CardChecklist, Geo, Activity, CloudSunFill,
  CloudMoon, CircleSquare, Icon3CircleFill, XCircle } from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './StudentRegistrationForm.css';

function StudentRegistrationForm() {
  const navigate = useNavigate();
  const fileInputRef = useRef();

  const [formData, setFormData] = useState({
    AdmissionNumber: '',
    name: '',
    image: null,
    imageUrl: '',
    grade: '',
    zone: '',
    location: '',
    tripFrequency: '',
    morningVehicle: '',
    afternoonVehicle: '',
    // schoolName: , // logic to prefill from user role
  });
  // eslint-disable-next-line 
  const [uploadURL, setUploadURL] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);
  const [validated, setValidated] = useState(false); // For form validation feedback

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.type !== 'image/jpeg') {
      setAlert({ type: 'danger', message: 'Wrong file type - JPG only.' });
      return;
    }
    if (file.size > 1000000) {
      setAlert({ type: 'danger', message: 'Image is too large.' });
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
        imageUrl: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteImage = () => {
    setFormData((prevData) => ({
      ...prevData,
      image: null,
      imageUrl: '',
    }));
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Image upload with retry logic (up to 3 attempts)
  const handleImageUpload = async (retryCount = 0) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_ENDPOINT);
      const uploadUrl = response.data.uploadURL;
      const Key = response.data.Key;

      const binary = atob(formData.imageUrl.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

      await fetch(uploadUrl, {
        method: 'PUT',
        body: blobData,
      });

      const imageUrl = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${Key}`;
      setUploadURL(imageUrl);
      console.log('Image uploaded successfully!');
      return imageUrl;
    } catch (error) {
      if (retryCount < 3) {
        console.warn(`Retrying image upload... (${retryCount + 1}/3)`);
        return handleImageUpload(retryCount + 1);
      } else {
        console.error('Image upload failed after 3 attempts:', error);
        setAlert({ type: 'danger', message: 'Failed to upload image after multiple attempts. Please try again.' });
        throw error;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    setIsSubmitting(true);
    setAlert(null);

    if (!formData.image) {
      setAlert({ type: 'danger', message: 'Please upload an image before submitting.' });
      setIsSubmitting(false);
      return;
    }

    try {
      let imageUrl = '';
      if (formData.image) {
        imageUrl = await handleImageUpload();
      }

      const studentData = {
        AdmissionNumber: formData.AdmissionNumber,
        name: formData.name,
        imageUrl: imageUrl,
        grade: formData.grade,
        zone: formData.zone,
        location: formData.location,
        tripFrequency: formData.tripFrequency,
        morningVehicle: formData.morningVehicle,
        afternoonVehicle: formData.afternoonVehicle,
        // schoolName: formData.schoolName,
      };

      const response = await axios.post(process.env.REACT_APP_API_DB_ENDPOINT, studentData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        // Reset form data after successful registration
        setFormData({
          AdmissionNumber: '',
          name: '',
          image: null,
          imageUrl: '',
          grade: '',
          zone: '',
          location: '',
          tripFrequency: '',
          morningVehicle: '',
          afternoonVehicle: '',
          // schoolName: , // the logic??
        });
        setUploadURL('');
  
        if (fileInputRef && fileInputRef.current) {
          fileInputRef.current.value = '';
        }
  
        setAlert({ type: 'success', message: 'Student registered successfully!' });
      }
    } catch (error) {
      // Check if the error is related to Admission Number
      if (error.response && error.response.status === 400) {
        // Admission number already exists
        setAlert({ type: 'danger', message: 'Admission Number already exists. Please use a different one.' });
      } else {
        // Other errors
        setAlert({ type: 'danger', message: '(500) An error occurred. Please try again.' });
      }
      console.error('Error in form submission:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <Container className="student-registration-form">
      <div className='registration-container'>
        <div className="header-section">
          <Button
            variant="primary"
            onClick={() => navigate(-1)}
            className="back-button"
          >
            Back
          </Button>
        </div>
        <h2 className="registration-heading">Student Onboarding</h2>
        {alert && (
          <Alert variant={alert.type} onClose={() => setAlert(null)} dismissible>
            {alert.message}
          </Alert>
        )}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><Icon3CircleFill /> Admission Number</Form.Label>
                <Form.Control
                  type="text"
                  name="AdmissionNumber"
                  value={formData.AdmissionNumber}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Admission Number is required.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><CardChecklist /> Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Name is required.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label><PersonBadge /> Upload Image</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                type="file"
                accept="image/jpeg"
                onChange={handleImageChange}
                ref={fileInputRef}
                required
              />
              {formData.imageUrl && (
                <Button variant="danger" onClick={handleDeleteImage} className="ms-2">
                  <XCircle />
                </Button>
              )}
            </div>
            {formData.imageUrl && <img src={formData.imageUrl} alt="Preview" style={{ width: '30%', marginTop: '10px' }} />}
            <Form.Control.Feedback type="invalid">
              Please upload an image.
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><CircleSquare /> Grade</Form.Label>
                <Form.Select
                  name="grade"
                  value={formData.grade}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Grade</option>
                  {['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th'].map((grade) => (
                    <option key={grade} value={grade}>{grade}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a grade.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><Geo /> Zone</Form.Label>
                <Form.Control
                  type="text"
                  name="zone"
                  value={formData.zone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label><FontAwesomeIcon icon={faMapMarkerAlt} /> Location</Form.Label>
            <Form.Control
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><Activity /> Trip Frequency</Form.Label>
            <Form.Select
              name="tripFrequency"
              value={formData.tripFrequency}
              onChange={handleChange}
            >
              <option value="">Select Frequency</option>
              {['Morning (1 Way)', 'Afternoon (1 Way)', 'Morning & Afternoon (2 Way)'].map((frequency) => (
                <option key={frequency} value={frequency}>{frequency}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><CloudSunFill /> Morning Vehicle</Form.Label>
                <Form.Control
                  type="text"
                  name="morningVehicle"
                  value={formData.morningVehicle}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><CloudMoon /> Afternoon Vehicle</Form.Label>
                <Form.Control
                  type="text"
                  name="afternoonVehicle"
                  value={formData.afternoonVehicle}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          
          <Button 
            type="submit" 
            variant="primary" 
            className="registration-button" 
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {' '}Registering...
              </>
            ) : 'Register Student'}
          </Button>
        </Form>
      </div>
    </Container>
  );
}

export default StudentRegistrationForm;