import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './LogisticsHome.css';
import { Link, useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { PersonLinesFill, Speedometer2, CameraVideo, PersonUp, PersonDown, PersonRolodex } from 'react-bootstrap-icons';

function LogisticsPlatformHome({ signOut, user }) {
    const navigate = useNavigate();

    const offerings = [
      
        { 
          name: "Administartor's Panel", 
          icon: <PersonLinesFill size={48} />, 
          description: "Access comprehensive profiles of student, for onboarding, registration, teacher/parent communication, and route assignment.",
        //   path: "/admin"
        },
        { 
            name: "Vehicle Live Video", 
            icon: <CameraVideo size={48} />, 
            description: "Stream real-time video from vehicles to monitor activities, enhance security, and ensure compliance with safety protocols.",
            // path: "/vehicle-live-video"
        },
        { 
            name: "Teacher's Panel", 
            icon: <PersonRolodex size={48} />, 
            description: "Teachers incharge of a particular group of students/grade get instant updates should the logistics manager make any changes or updates.",
            // path: "/teachers-section"
        },      
        { 
            name: "Vehicle Odometer", 
            icon: <Speedometer2 size={48} />, 
            description: "The Odometer tool provides real-time data on distance traveled.",
            path: "/vehicle-odometer"
        },
        { 
          name: "Morning Trip", 
          icon: <PersonUp size={48} />, 
          description: "Ensure students to and from school, board the right vehicle, and are checked in safely.",
        //   path: "/steward-checkin"
        },        
        { 
            name: "Afternoon Trip", 
            icon: <PersonDown size={48} />, 
            description: "Ensure students are checked out of vehicles safely and can be accounted for.",
            // path: "/student-checkin"
        },

    ];

    return (
        <div className="container-fluid mt-4">
            <div className="header-section">
                <Button 
                    variant="contained"
                    onClick={() => navigate(-1)} 
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                >
                    Back
                </Button>
            </div>
            <div className="row">
                {offerings.map((offering, index) => (
                    <div className="col-6 col-sm-4 col-md-3 p-2 d-flex align-items-stretch" key={index}>
                        <Link to={offering.path} className="text-decoration-none w-100">
                            <div className="card mb-4 flex-grow-1 text-left h-100">
                                <div className="card-body d-flex flex-column justify-content-center">
                                    {offering.icon}
                                    <h5 className="card-title mt-3">{offering.name}</h5>
                                    <p className="card-text">{offering.description}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

// export default withAuthenticator(LogisticsPlatformHome, {
//   theme,
// });

export default LogisticsPlatformHome