import React, { useState } from 'react';
import './TeacherSection.css';
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

const students = [
  { id: 1, name: 'Machio Brian', number: '10457', zone: 'North', morningVehicle: 'Bus 1', afternoonVehicle: 'Bus 3', status: 'Cleared' },
  { id: 2, name: 'Kevin Mutai', number: '10455', zone: 'East', morningVehicle: 'Bus 2', afternoonVehicle: 'Bus 1', status: 'Pending' },
  { id: 3, name: 'John Doe', number: '10456', zone: 'West', morningVehicle: 'Bus 1', afternoonVehicle: 'Bus 4', status: 'Pending' },
  { id: 4, name: 'Jane Smith', number: '10458', zone: 'South', morningVehicle: 'Bus 3', afternoonVehicle: 'Bus 2', status: 'Pending' },
];

function VehicleBadge({ vehicle }) {
  const getVehicleClass = (vehicle) => {
    switch (vehicle) {
      case 'Bus 1':
        return 'teacher-section__vehicle--bus1';
      case 'Bus 2':
        return 'teacher-section__vehicle--bus2';
      case 'Bus 3':
        return 'teacher-section__vehicle--bus3';
      case 'Bus 4':
        return 'teacher-section__vehicle--bus4';
      default:
        return 'teacher-section__vehicle--default';
    }
  };

  return (
    <span className={`teacher-section__vehicle ${getVehicleClass(vehicle)}`}>
      {vehicle}
    </span>
  );
}

function StudentDetails({ student }) {
  return (
    <div className="teacher-section__details">
      <div className="teacher-section__student-image">
        <Avatar sx={{ width: 150, height: 150 }}>
          <PersonIcon sx={{ fontSize: 80 }} />
        </Avatar>
      </div>
      <h2 className="teacher-section__details-title">Student Details</h2>
      <p className="teacher-section__details-info"><strong>Admission Number:</strong> {student.number}</p>
      <p className="teacher-section__details-info"><strong>Zone:</strong> {student.zone}</p>
      <p className="teacher-section__details-info">
        <strong>Morning Vehicle:</strong> <VehicleBadge vehicle={student.morningVehicle} />
      </p>
      <p className="teacher-section__details-info">
        <strong>Afternoon Vehicle:</strong> <VehicleBadge vehicle={student.afternoonVehicle} />
      </p>
      <p className="teacher-section__details-info">
        <strong>Payment Status:</strong> 
        <span className={`teacher-section__status teacher-section__status--${student.status.toLowerCase()}`}>
          {student.status}
        </span>
      </p>
    </div>
  );
}

function TeacherSection() {
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const navigate = useNavigate();

  const handleStudentClick = (student) => {
    setSelectedStudent(selectedStudent?.id === student.id ? null : student);
  };

  const filteredStudents = students.filter((student) =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    student.number.includes(searchTerm)
  );

  const sortedStudents = [...filteredStudents].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="teacher-section">
      <div className="teacher-section__header">
        <button 
          className="check-in-screen__back-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        <input
          type="text"
          className="teacher-section__search"
          placeholder="Search by name or admission number."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="teacher-section__main">
        <div className="teacher-section__table-section">
          <table className="teacher-section__table">
            <thead>
              <tr>
                <th onClick={() => handleSort('name')}>Name</th>
                <th onClick={() => handleSort('number')}>Number</th>
                <th onClick={() => handleSort('zone')}>Zone</th>
                <th onClick={() => handleSort('afternoonVehicle')}>Afternoon Vehicle</th>
              </tr>
            </thead>
            <tbody>
              {sortedStudents.map((student) => (
                <React.Fragment key={student.id}>
                  <tr
                    onClick={() => handleStudentClick(student)}
                    className={selectedStudent?.id === student.id ? 'selected' : ''}
                  >
                    <td>{student.name}</td>
                    <td>{student.number}</td>
                    <td>{student.zone}</td>
                    <td><VehicleBadge vehicle={student.afternoonVehicle} /></td>
                  </tr>
                  {selectedStudent?.id === student.id && (
                    <tr className="teacher-section__details-row">
                      <td colSpan="4">
                        <StudentDetails student={student} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TeacherSection;
