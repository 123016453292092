import React from 'react';
import './StewardCheckinScreen.css';
import { useNavigate } from 'react-router-dom';

function LoginScreen() {
  const navigate = useNavigate();

  return (
    <div className="login-screen">
      <button className="login-screen__back-button" onClick={() => navigate(-1)}>
        Back
      </button>
      
      <div className="login-screen__container">
        <form className="login-screen__form">
          <h1 className="login-screen__title">Check In</h1>
          <input 
            type="text" 
            placeholder="School Name" 
            className="login-screen__input-field"
          />
          
          <select className="login-screen__select-field">
            <option value="" disabled selected>Zone</option>
            {/* Add zone options here */}
          </select>
          
          <select className="login-screen__select-field">
            <option value="" disabled selected>Vehicle</option>
            {/* Add vehicle options here */}
          </select>
          
          <button type="submit" className="login-screen__login-button">LOGIN</button>
        </form>
      </div>
    </div>
  );
}

export default LoginScreen;