import React from "react";
import { EnvelopeFill, ClockFill } from 'react-bootstrap-icons';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faServer, faCode, faRobot, faCoffee } from '@fortawesome/free-solid-svg-icons'; // Importing FontAwesome Icons
import { useNavigate } from "react-router-dom";
import './Careers.css';

function Career() {
    const navigate = useNavigate();

    return (
        <div className="container mt-4">
            <Button 
                variant="contained"
                onClick={() => navigate(-1)} 
                className="backButton"
                // startIcon={<i className="bi bi-arrow-left-circle"></i>}
            >
                Back
            </Button>
            
            <h1 className="text-center career-heading">
                        Hey techie!  Reach Out.
            </h1>

            {/* Application Section */}
            <div className="career-section techie-section">
                <h4 className="section-heading">
                    <EnvelopeFill size={24} className="icon" /> Application
                </h4>
                <p><a href="mailto:careers@data-im.com" className="career-link">careers@data-im.com</a></p>
                <ul className="career-list">
                    <li>
                        <FontAwesomeIcon icon={faLaptopCode} className="tech-icon" />
                        Show off your skills. Attach your portfolio or project to showcase your technical prowess.
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faServer} className="tech-icon" />
                        Let your personality shine through in your application materials.
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCode} className="tech-icon" />
                        Become part of a dynamic and collaborative tech community.
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faRobot} className="tech-icon" />
                        Work with/on both legacy systems and modern technologies.
                    </li>
                </ul>
            </div>

            {/* Combined career Section */}
            <div className="career-section">
                {/* <h4 className="section-heading">
                    <TelephoneFill size={24} className="icon" /> Phone
                </h4>
                <ul className="career-list">
                    <li>(+254) 788 048 240</li>
                    <li>(+254) 115 566 678</li>
                </ul>

                <h4 className="section-heading">
                    <GeoAltFill size={24} className="icon" /> Address
                </h4>
                <p>Karen Village, Nairobi, Kenya</p> */}

                {/* Office Hours Section */}
                <h4 className="section-heading">
                <ClockFill size={24} className="icon" /> Office Hours
                </h4>
                <div className="office-hours">
                    <div className="office-hour-item">
                        <span className="day">Monday - Friday</span>
                        <span className="time">09:00 - 17:00</span>
                    </div>
                    <div className="office-hour-item">
                        {/* <span className="day">Saturday</span> */}
                        <span>
                        <FontAwesomeIcon icon={faCoffee} className="tech-icon" />
                        <FontAwesomeIcon icon={faLaptopCode} className="tech-icon" />
                             {/* Micromouse & CTF   */}
                        </span>
                        <span className="time">08:00 - 13:00</span>
                    </div>
                    <div className="office-hour-item closed">
                        <span className="day">Sunday</span>
                        <span className="time">Closed</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Career;