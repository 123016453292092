import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CheckInScreen.css';
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const students = [
  { id: 1, name: 'Machio Brian', number: '10457', status: 'Cleared' },
  { id: 2, name: 'Kevin Mutai', number: '10455', status: 'Pending' },
  { id: 3, name: 'John Doe', number: '10456', status: 'Pending' },
  { id: 4, name: 'Jane Smith', number: '10458', status: 'Pending' },
];

function StudentDetails({ student }) {
  return (
    <div className="check-in-screen__details">
      <div className="check-in-screen__student-image">
        <Avatar sx={{ width: 150, height: 150 }}>
          <PersonIcon sx={{ fontSize: 80 }} />
        </Avatar>
      </div>
      <h2 className="check-in-screen__details-title">Student Details</h2>
      <p className="check-in-screen__details-info">Grade: 5th</p>
      <p className="check-in-screen__details-info">Zone: North</p>
      <div className="check-in-screen__contact">
        <h3 className="check-in-screen__contact-title">Parent Contact:</h3>
        <div className="check-in-screen__contact-buttons">
          <button className="check-in-screen__contact-button">Call</button>
          <button className="check-in-screen__contact-button">Text</button>
        </div>
      </div>
    </div>
  );
}

function CheckInScreen() {
  const navigate = useNavigate();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleStudentClick = (student) => {
    setSelectedStudent(selectedStudent?.id === student.id ? null : student);
  };

  const filteredStudents = students.filter((student) =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    student.number.toLowerCase().includes(searchTerm.toLowerCase()) // Adding search by number
  );

  return (
    <div className="check-in-screen">
      <div className="check-in-screen__header">
        <div className="check-in-screen__header-content">
          <button 
            className="check-in-screen__back-button"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <div className="check-in-screen__search-container">
            <input
              type="text"
              className="check-in-screen__search"
              placeholder="Search by name or admission number."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="check-in-screen__main">
        <div className="check-in-screen__table-section">
          <table className="check-in-screen__table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Number</th>
                <th>Payment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student) => (
                <React.Fragment key={student.id}>
                  <tr
                    onClick={() => handleStudentClick(student)}
                    className={selectedStudent?.id === student.id ? 'selected' : ''}
                  >
                    <td>{student.name}</td>
                    <td>{student.number}</td>
                    <td>
                      <span className={`check-in-screen__status check-in-screen__status--${student.status.toLowerCase()}`}>
                        {student.status}
                      </span>
                    </td>
                    <td>
                      <button className="check-in-screen__check-in-button">
                        CheckIn
                      </button>
                    </td>
                  </tr>
                  {/* Mobile details row */}
                  {selectedStudent?.id === student.id && (
                    <tr className="check-in-screen__details-row">
                      <td colSpan="4">
                        <div className="check-in-screen__details-mobile">
                          <StudentDetails student={student} />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <button className="check-in-screen__complete-button">
        Complete Boarding
      </button>
    </div>
  );
}

export default CheckInScreen;
