import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import axios from 'axios';
import { 
  Button, TextField, Select, MenuItem, FormControl, 
  Card, Grid, Typography, Box, Alert, Snackbar, Table, 
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
  Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, HomeWork as HomeIcon,
  People as PeopleIcon, Room as RoomIcon,
  ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#232F3E',
    },
    secondary: {
      main: '#FF9900',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#DEE2E6',
          padding: '12px 15px',
          fontSize: '16px',
          textTransform: 'capitalize',
        },
        head: {
          backgroundColor: '#F1F3F5',
          color: '#232F3E',
          fontWeight: 600,
          letterSpacing: '0.05em',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#F9F9F9',
          },
          '&:hover': {
            backgroundColor: '#ff990056',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
}) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
    setIsEditing(false);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const renderEditContent = () => {
    if (id === 'Trip') {
      return (
        <FormControl fullWidth>
          <Select value={value || ''} onChange={onChange} onBlur={onBlur} autoFocus>
            <MenuItem value="Morning (1 Way)">Morning (1 Way)</MenuItem>
            <MenuItem value="Afternoon (1 Way)">Afternoon (1 Way)</MenuItem>
            <MenuItem value="Morning & Afternoon (2 Way)">Morning & Afternoon (2 Way)</MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      return (
        <TextField
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus
          fullWidth
        />
      );
    }
  };

  return (
    <Box 
      sx={{ 
        position: 'relative',
        '&:hover .edit-icon': {
          opacity: 1,
        },
      }}
      onMouseEnter={() => setShowEditIcon(true)}
      onMouseLeave={() => setShowEditIcon(false)}
    >
      {isEditing ? (
        renderEditContent()
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '24px' }}>
          <Typography>{value || ''}</Typography>
          <IconButton 
            className="edit-icon"
            onClick={() => setIsEditing(true)} 
            size="small"
            sx={{ 
              opacity: showEditIcon ? 1 : 0,
              transition: 'opacity 0.3s',
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

const ViewEditStudentRecords = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalStudents, setTotalStudents] = useState(0);
  const [uniqueZones, setUniqueZones] = useState(0);
  const [uniqueLocations, setUniqueLocations] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);
  const navigate = useNavigate();

  const calculateCounts = useCallback((studentData) => {
    setTotalStudents(studentData.length);
    setUniqueZones(new Set(studentData.map(student => student.Zone)).size);
    setUniqueLocations(new Set(studentData.map(student => student.Location)).size);
  }, []);

  const fetchStudents = useCallback(async () => {
    try {
      setLoading(true);
      const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
      const response = await axios.get(`${apiEndpoint}/view-and-edit-student-data`);
      const studentData = response.data;
      setStudents(studentData);
      setLoading(false);
      calculateCounts(studentData);
    } catch (error) {
      setError('Failed to fetch students. Please try again later.');
      setLoading(false);
    }
  }, [calculateCounts]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  const handleDelete = useCallback(async (admissionNumber, Grade, Name) => {
    try {
      const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
      const requestBody = { 
        AdmissionNumber: admissionNumber,
        Grade: Grade
      };
      await axios.delete(`${apiEndpoint}/view-and-edit-student-data`, { data: requestBody });
      setStudents(prevStudents => {
        const updatedStudents = prevStudents.filter(student => student.AdmissionNumber !== admissionNumber);
        calculateCounts(updatedStudents);
        return updatedStudents;
      });
      setAlertMessage({ type: 'success', text: `Successfully deleted ${Name} (${admissionNumber})` });
    } catch (error) {
      console.error('Error deleting student:', error);
      setAlertMessage({ type: 'error', text: 'Failed to delete student. Please try again.' });
    }
  }, [calculateCounts]);

  const updateMyData = useCallback(async (rowIndex, columnId, value) => {
    try {
      setStudents(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value
            };
          }
          return row;
        })
      );

      const updatedData = {
        ...students[rowIndex],
        [columnId]: value
      };
      console.log(updatedData);
      const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
      await axios.put(`${apiEndpoint}/view-and-edit-student-data`, updatedData);
      
      setAlertMessage({ type: 'success', text: 'Student data updated successfully.' });
    } catch (error) {
      setAlertMessage({ type: 'error', text: 'Failed to update student. Please try again.' }); 
    }
  }, [students]);

  const columns = useMemo(
    () => [
      {
        Header: 'Admission Number',
        accessor: 'AdmissionNumber', // Attribute Name
        // Cell: EditableCell,
      },
      {
        Header: 'Name',
        accessor: 'Name',
        Cell: EditableCell,
      },
      {
        Header: 'Grade',
        accessor: 'Grade',
        // Cell: EditableCell,
      },
      {
        Header: 'Location',
        accessor: 'Location',
        Cell: EditableCell,
      },
      {
        Header: 'Trip',
        accessor: 'Trip',
        Cell: EditableCell,
      },
      {
        Header: 'Zone',
        accessor: 'Zone',
        Cell: EditableCell,
      },
      {
        Header: 'Morning Vehicle',
        accessor: 'MorningVehicle',
        Cell: EditableCell,
      },
      {
        Header: 'Afternoon Vehicle',
        accessor: 'AfternoonVehicle',
        Cell: EditableCell,
      },
      {
        Header: 'Delete Record',
        accessor: 'actions',
        Cell: ({ row }) => (
          <IconButton onClick={() => handleDelete(row.original.AdmissionNumber, row.original.Grade, row.original.Name)} color="error">
            <DeleteIcon />
          </IconButton>
        ),
        disableSortBy: true,
      },
    ],
    [handleDelete]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    { 
      columns, 
      data: students,
      updateMyData,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress sx={{ color: theme.palette.secondary.main }} />
    </Box>
  );  
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ maxWidth: 1800, margin: '0 auto', padding: '20px', color: '#232F3E' }}>
      <Button 
                    variant="contained"
                    onClick={() => navigate(-1)} 
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                    // startIcon={<i className="bi bi-arrow-left-circle"></i>}
                >
                    Back
                </Button>
        <Typography 
        variant="h4" 
        component="h1" 
        gutterBottom 
        sx={{ color: 'secondary.main', 
              marginTop: 3, 
              textAlign: 'center', 
              marginBottom: 3, 
              fontWeight: 'bold' }}>
          Student Records
        </Typography>

        <Snackbar 
          open={!!alertMessage} 
          autoHideDuration={6000} 
          onClose={() => setAlertMessage(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setAlertMessage(null)} severity={alertMessage?.type} sx={{ width: '100%' }}>
            {alertMessage?.text}
          </Alert>
        </Snackbar>

        <TextField
          label="Search"
          variant="outlined"
          value={globalFilter || ''}
          onChange={e => setGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          fullWidth
          margin="normal"
          sx={{ marginBottom: 3 }}
        />

        <TableContainer component={Paper} sx={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: 4 }}>
          <Table {...getTableProps()} sx={{ minWidth: 650 }}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell 
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#E0E0E0',
                        },
                      }}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDownwardIcon fontSize="small" />
                            : <ArrowUpwardIcon fontSize="small" />
                          : ''}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container spacing={3} sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={4}>
            <Card sx={{ 
              textAlign: 'center', 
              padding: 2, 
              height: 120, 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center',
              alignItems: 'center',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }
            }}>
              <PeopleIcon color="primary" sx={{ fontSize: 40, marginBottom: 1 }} />
              <Typography variant="h6" sx={{ marginBottom: 0.5, fontWeight: 200 }}>Total Students</Typography>
              <Typography variant="h6" sx={{ fontWeight: 200 }}>{totalStudents}</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ 
              textAlign: 'center', 
              padding: 2, 
              height: 120, 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center',
              alignItems: 'center',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }
            }}>
              <RoomIcon color="primary" sx={{ fontSize: 40, marginBottom: 1 }} />
              <Typography variant="h6" sx={{ marginBottom: 0.5, fontWeight: 200 }}>Zones</Typography>
              <Typography variant="h6" sx={{ fontWeight: 200 }}>{uniqueZones}</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ 
              textAlign: 'center', 
              padding: 2, 
              height: 120, 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center',
              alignItems: 'center',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }
            }}>
              <HomeIcon color="secondary" sx={{ fontSize: 40, marginBottom: 1 }} />
              <Typography variant="h6" sx={{ marginBottom: 0.5, fontWeight: 200 }}>Locations</Typography>
              <Typography variant="h6" sx={{ fontWeight: 200 }}>{uniqueLocations}</Typography>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default ViewEditStudentRecords;