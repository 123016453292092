import React, { useState, useEffect } from "react";
import { Map, APIProvider, Marker, Pin, InfoWindow, useMap } from "@vis.gl/react-google-maps";
import "./vehicleRoute.css";
import locationsData from './locations.json';
import { useNavigate } from "react-router-dom";
import { 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Card, 
  CardContent, 
  Typography 
} from '@mui/material';

const MapContent = () => {
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [viaPoints, setViaPoints] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const map = useMap();

  useEffect(() => {
    setOrigin(locationsData.origin);
    setDestination(locationsData.destination);
    setViaPoints(locationsData.via);
  }, []);

  useEffect(() => {
    if (map && origin && destination && viaPoints.length > 0) {
      const routePath = [
        origin,
        ...viaPoints,
        destination
      ];

      const polyline = new window.google.maps.Polyline({
        path: routePath,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      polyline.setMap(map);

      return () => polyline.setMap(null);
    }
  }, [map, origin, destination, viaPoints]);

  return (
    <>
      {origin && (
        <Marker position={origin} onClick={() => setSelectedMarker(origin)}>
          <Pin background={"green"} borderColor={"black"} glyphColor={"white"} />
        </Marker>
      )}
      {destination && (
        <Marker position={destination} onClick={() => setSelectedMarker(destination)}>
          <Pin background={"red"} borderColor={"black"} glyphColor={"white"} />
        </Marker>
      )}
      {viaPoints.map((point, index) => (
        <Marker 
          key={index} 
          position={point} 
          onClick={() => setSelectedMarker(point)}
        >
          <Pin background={"blue"} borderColor={"black"} glyphColor={"white"} />
        </Marker>
      ))}
      {selectedMarker && (
        <InfoWindow position={selectedMarker} onCloseClick={() => setSelectedMarker(null)}>
          <div>{selectedMarker.name}</div>
        </InfoWindow>
      )}
    </>
  );
};

const CustomMap = () => {
  const mapCenter = {
    lat: (locationsData.origin.lat + locationsData.destination.lat) / 2,
    lng: (locationsData.origin.lng + locationsData.destination.lng) / 2
  };
  const navigate = useNavigate();

  const [selectedSchool, setSelectedSchool] = useState('');
  const [timeOfDay, setTimeOfDay] = useState('');
  const [selectedZone, setSelectedZone] = useState('');

  const schools = ['School A', 'School B', 'School C'];
  const zones = ['Zone 1', 'Zone 2', 'Zone 3'];

  const routeDetails = {
    totalDistance: "15.5 km",
    stopoverPoints: 7,
    zoneName: "Central Nairobi",
    tripDuration: "45 minutes"
  };

  return (
    <div className="app">
      <div className="back-button">
        <Button 
          variant="contained"
          onClick={() => navigate(-1)} 
          sx={{ 
            backgroundColor: '#232F3E', 
            color: '#FFFFFF', 
            '&:hover': { backgroundColor: '#1D2731' },
            fontSize: '14px',
            borderRadius: '10px',
            padding: '8px 16px',
          }}
        >
          Back
        </Button>
      </div>

      <div className="controls-container">
        <Card>
          <CardContent>
            <FormControl fullWidth margin="normal">
              <InputLabel>School</InputLabel>
              <Select
                value={selectedSchool}
                label="School"
                onChange={(e) => setSelectedSchool(e.target.value)}
              >
                {schools.map((school) => (
                  <MenuItem key={school} value={school}>{school}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Zone</InputLabel>
              <Select
                value={selectedZone}
                label="Zone"
                onChange={(e) => setSelectedZone(e.target.value)}
              >
                {zones.map((Zone) => (
                  <MenuItem key={Zone} value={Zone}>{Zone}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Trip</InputLabel>
              <Select
                value={timeOfDay}
                label="Time of Day"
                onChange={(e) => setTimeOfDay(e.target.value)}
              >
                <MenuItem value="morning">Morning</MenuItem>
                <MenuItem value="afternoon">Afternoon</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      </div>

      <div className="map-container">
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <Map
            center={mapCenter}
            zoom={14}
            mapId="default"
          >
            <MapContent />
          </Map>
        </APIProvider>
      </div>
      
      <div className="details-container">
        <Card className="detail-card">
          <CardContent>
            <Typography variant="h6" gutterBottom>Total Distance</Typography>
            <Typography variant="body1">{routeDetails.totalDistance}</Typography>
          </CardContent>
        </Card>
        <Card className="detail-card">
          <CardContent>
            <Typography variant="h6" gutterBottom>Stopover Points</Typography>
            <Typography variant="body1">{routeDetails.stopoverPoints}</Typography>
          </CardContent>
        </Card>
        <Card className="detail-card">
          <CardContent>
            <Typography variant="h6" gutterBottom>Zone Name</Typography>
            <Typography variant="body1">{routeDetails.zoneName}</Typography>
          </CardContent>
        </Card>
        <Card className="detail-card">
          <CardContent>
            <Typography variant="h6" gutterBottom>Trip Duration</Typography>
            <Typography variant="body1">{routeDetails.tripDuration}</Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default CustomMap;