import React from "react";
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import './Industrial.css';
import cellsoft from '../../assets/cellsoft.png';
import totalenergies from '../../assets/totalenergy.png';
import sunday from '../../assets/sunday.png';
import furahabuild from '../../assets/furaha.png';

function Industrial() {
    const navigate = useNavigate();

    return (
        <div className="container mt-4">
            <Button 
                variant="contained"
                onClick={() => navigate(-1)} 
                className="backButton"
            >
                Back
            </Button>

            <h1 className="text-center industrial-heading">
                Industrial Insights: The Power of Connectivity
            </h1>

            <div className="industrial-section">
                <p className="industrial-text">
                    <strong>Real-time analytics and interconnected systems</strong> are revolutionizing modern industry, enabling unprecedented efficiency, insight, and control. In today's rapidly evolving technological landscape, understanding and leveraging these technologies is crucial for businesses and stakeholders across all sectors.
                </p>
                <p className="industrial-text">
                    The power of connectivity lies in its ability to create a seamless network of devices, sensors, and systems that continuously exchange information. This Internet of Things (IoT) ecosystem provides tools to:
                </p>
                <ul className="industrial-list">
                    <li>Anticipate trends and predict maintenance needs</li>
                    <li>Optimize operations through data-driven decision making</li>
                    <li>Adapt swiftly to market changes and operational demands</li>
                    <li>Enhance monitoring capabilities for improved safety and efficiency</li>
                    <li>Implement robust control mechanisms for streamlined processes</li>
                </ul>
                <p className="industrial-text">
                    By harnessing the potential of interconnected devices and real-time data analytics, businesses can achieve:
                </p>
                <ul className="industrial-list">
                    <li>Increased operational efficiency and reduced downtime</li>
                    <li>Enhanced product quality and consistency</li>
                    <li>Improved resource management and sustainability</li>
                    <li>Greater agility in responding to market demands</li>
                    <li>Accelerated innovation and product development cycles</li>
                </ul>
                <p className="industrial-text">
                    As we progress further into the digital age, mastering these technologies is not just advantageous—it's imperative for maintaining a competitive edge. Organizations that successfully integrate and leverage interconnected systems and real-time analytics will be well-positioned to lead in our increasingly data-driven world, setting new standards for efficiency, innovation, and customer satisfaction.
                </p>
            </div>

            <div className="partner-section">
                <h2 className="section-heading">Completed Projects</h2>
                <div className="partner-list">
                    <div className="partner-item">
                        <img src={totalenergies} alt="Total Energies Logo" className="partner-logo" />
                        <div className="partner-details">
                            <h3 className="section-heading">Total Energies & Inverter Power Systems, Nairobi Kenya</h3>
                            <p className="project-duration">Power Monitoring Solutions (June 2024 - August 2024)</p>
                            <p className="project-description">
                                We collaborated with Total Energies and Inverter Power Systems to develop a power and UPS monitoring tool. This solution significantly 
                                enhanced system reliability through quantifiable risk analysis and proactive issue anticipation. By implementing real-time tracking - 
                                automation- we drastically reduced potential downtime and minimized the need for manual intervention.
                            </p>
                            <h4 className="section-heading">Key Achievements:</h4>
                            <ul>
                                <li>Developed real-time power and UPS monitoring solutions with predictive analytics capabilities.</li>
                                <li>Enhanced system reliability through integration with existing energy systems and implementation of risk assessment protocols.</li>
                                <li>Improved operational efficiency via automated reporting and proactive maintenance scheduling.</li>
                                <li>Substantially reduced system downtime through early issue detection and rapid response mechanisms.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="partner-item">
                        <img src={furahabuild} alt="FurahaBuild Ventures Logo" className="partner-logo" />
                        <div className="partner-details">
                            <h3 className="section-heading">FurahaBuild Ventures Ltd, Nairobi Kenya</h3>
                            <p className="project-duration">Internet of Things Solutions (April 2023 - July 2024)</p>
                            <p className="project-description">
                                In collaboration with FurahaBuild Ventures Ltd, we led customer discovery, design, and deployment of tailored IoT solutions. The project encompassed intensive prototyping and seamless integration of IoT systems, showcasing the power of interconnected devices in construction and real estate.
                            </p>
                            <h4 className="section-heading">Project Highlights:</h4>
                            <ul>
                                <li>Conducted comprehensive user discovery to identify key IoT system requirements.</li>
                                <li>Designed and implemented customized IoT solutions for smart building management.</li>
                                <li>Managed deployment and provided ongoing support, ensuring system reliability.</li>
                                <li>Refined prototypes based on user testing and feedback, optimizing performance.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="partner-item">
                        <img src={cellsoft} alt="Cellsoft Technologies Logo" className="partner-logo" />
                        <div className="partner-details">
                            <h3 className="section-heading">Cellsoft Technologies, Nairobi Kenya</h3>
                            <p className="project-duration">IoT Infrastructure Consulting (October 2023 - January 2024)</p>
                            <p className="project-description">
                                We collaborated with Cellsoft Technologies to develop a scalable backend infrastructure for a LoRaWAN-based IoT platform. The solution enhanced device management, security, and data analysis capabilities, demonstrating the potential of wide-area networks in IoT applications.
                            </p>
                            <h4 className="section-heading">Key Deliverables:</h4>
                            <ul>
                                <li>Designed and implemented a scalable LoRaWAN backend infrastructure.</li>
                                <li>Enhanced device management capabilities for improved control and monitoring.</li>
                                <li>Implemented robust security measures to protect sensitive IoT data.</li>
                                <li>Developed advanced data analysis tools for actionable insights.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="partner-item">
                        <img src={sunday} alt="Sunday Robotics Logo" className="partner-logo" />
                        <div className="partner-details">
                            <h1 className="section-heading">Sunday Robotics, Antalya Turkiye</h1>
                            <p className="project-duration">Machine Learning Collaboration (September 2022 - March 2023)</p>
                            <p className="project-description">
                                In partnership with Sunday Robotics, we developed an embedded offline speech recognition system for electrical control panels. The system utilizes machine learning to ensure robust performance in offline environments, showcasing the integration of AI in industrial control systems.
                            </p>
                            <h4 className="section-heading">Project Achievements:</h4>
                            <ul>
                                <li>Implemented an innovative offline speech recognition system using advanced machine learning techniques.</li>
                                <li>Optimized performance for electrical distribution control panels in challenging environments.</li>
                                <li>Collaborated with international research teams to enhance functionality and reliability.</li>
                                <li>Successfully showcased the product at a prominent international tech exposition, garnering industry attention.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Industrial;